import React from "react";
import * as styles from "./terms.module.scss";
import { TermsBlockContent } from "../BlockContent/TermsBlockContent";
import { graphql, useStaticQuery } from "gatsby";
import { usePageContext } from "../Context/pageContext";
import { TermsQuery } from "../../../graphql-types";

export const Terms = () => {
  const { legals } = useStaticQuery<TermsQuery>(query);
  const { lang } = usePageContext();

  return (
    <div className={styles.content}>
      {legals?.content?.termsList?.map((e: any, index: number) => {
        return (
          <div className={styles.block} key={`terms-${index}`}>
            <div className={styles.title}>{e?._rawTitle?.[lang]}</div>
            <div className={styles.text}>
              <TermsBlockContent blocks={e?._rawContent?.[lang]} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const query = graphql`
  query Terms {
    legals: sanityLegalsPage {
      content {
        termsList {
          _rawTitle
          _rawContent
        }
      }
    }
  }
`;
