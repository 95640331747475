import { Link, graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import { Logo } from "../Logo/Logo";
import { Newsletter } from "../Newsletter/Newsletter";
import { Socials, Theme } from "../Socials/Socials";
import * as styles from "./menu.module.scss";
import { MenuQuery } from "../../../graphql-types";
import { usePageContext } from "../Context/pageContext";

interface Props {
  open: boolean;
  closeMenu: any;
}

export const Menu: React.FC<Props> = ({ open, closeMenu }) => {
  const { menu } = useStaticQuery<MenuQuery>(query);
  const { lang, defaultLang } = usePageContext();
  // const [originalStyle, setOriginalStyle] = useState<any>();

  // useEffect(() => {
  //   setOriginalStyle(window.getComputedStyle(document.body).overflow);
  // }, []);

  // useEffect(() => {
  //   if (open === true) lockBodyScroll();
  //   if (open === false) document.body.style.overflow = originalStyle;
  // }, [open]);

  return (
    <div className={`${styles.wrapper} ${open && styles.visible}`}>
      <div className={styles.topContainer}>
        <Logo theme={Theme.black} />
      </div>
      <div className={styles.middleContainer}>
        <div className={styles.linksContainer}>
          {menu?.menuItems
            ?.filter((item: any) => item.active === true)
            .map((item: any, index: number) => (
              <React.Fragment key={`${index}-itemMenu`}>
                <Link
                  to={lang === defaultLang ? item.path : `${item.path}/${lang}`}
                  onClick={() => {
                    // document.body.style.overflow = originalStyle;
                    closeMenu();
                  }}
                  className={styles.link}
                >
                  {item._rawLabel[lang]}
                </Link>
              </React.Fragment>
            ))}

          <Link
            to={lang === defaultLang ? "/contact" : `/contact/${lang}`}
            // onClick={() => (document.body.style.overflow = originalStyle)}
            className={styles.link}
          >
            {"Contact us"}
          </Link>
        </div>
        <Socials theme={Theme.black} />
      </div>
      <div className={styles.bottomContainer}>
        <Newsletter theme={Theme.black} />
      </div>
    </div>
  );
};

// function lockBodyScroll() {
//   document.body.style.overflow = "hidden";
// }

const query = graphql`
  query Menu {
    menu: sanityMenu {
      menuItems {
        path
        _rawLabel
        active
      }
    }
  }
`;
