import React, { useState } from "react";
import * as styles from "./burgerMenu.module.scss";
import { Menu } from "./Menu";

export const BurgerMenu = React.memo(() => {
  const [menu, setMenu] = useState<boolean>(false);

  const handleClick = () => {
    setMenu(!menu);
  };
  return (
    <>
      <div className={styles.burgerIcon} onClick={handleClick}>
        <span className={menu ? styles.menuOpen : styles.menuClose} />
      </div>
      <Menu open={menu} closeMenu={() => setMenu(false)} />
    </>
  );
});
