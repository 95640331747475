// extracted by mini-css-extract-plugin
export var wrapper = "footer-module--wrapper--3yPKy";
export var container = "footer-module--container--2zELV";
export var topSection = "footer-module--topSection--2QbVF";
export var rightWrapper = "footer-module--rightWrapper--2LPah";
export var contact = "footer-module--contact--34eRb";
export var socials = "footer-module--socials--2Pyxo";
export var middleSection = "footer-module--middleSection--2xdk1";
export var links = "footer-module--links--1gzAj";
export var copyright = "footer-module--copyright--3jN4e";
export var terms = "footer-module--terms--1szze";
export var paddingTop = "footer-module--paddingTop--3hP7r";
export var termsNoFooter = "footer-module--termsNoFooter--3-Ng3";