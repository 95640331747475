import React, { useContext, useEffect, useState } from "react";
import * as styles from "./header.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingBag, faUser } from "@fortawesome/pro-light-svg-icons";
import { Logo } from "../Logo/Logo";
import { BurgerMenu } from "../Menu/BurgerMenu";
import { SnipcartContext } from "gatsby-plugin-snipcart-advanced/context";
import { Link } from "gatsby";
import { usePageContext } from "../Context/pageContext";

declare const window: any;

export const Header: React.FC = React.memo(() => {
  const { state } = useContext(SnipcartContext);
  const { userStatus, cartQuantity } = state;
  const [snip, setSnip] = useState<any>(undefined);
  const [trigger, setTrigger] = useState<boolean>(false);

  useEffect(() => {
    if (typeof window !== undefined) {
      window.addEventListener("scroll", () => {
        !trigger && window.scrollY > 0 && setTrigger(true);
        trigger && window.scrollY <= 0 && setTrigger(false);
      });
    }
    return () => {
      window.removeEventListener("scroll", () => {});
    };
  }, [trigger]);

  useEffect(() => {
    const { Snipcart } = window;
    if (!Snipcart) return;
    setSnip(Snipcart);
  }, []);

  return (
    <div className={`${styles.wrapper} ${trigger ? styles.fade : ""}`}>
      <div className={styles.navbar}>
        <div className={styles.bars}>
          <BurgerMenu />
        </div>
        <Link to="/" className={styles.logoContainer}>
          <Logo />
        </Link>
        <div className={styles.login}>
          <div
            className={styles.cart}
            onClick={() => {
              if (snip) snip?.api.theme.cart.open();
            }}
          >
            {/* <StyledLink to="/basket"> */}
            <FontAwesomeIcon className={styles.icon} icon={faShoppingBag} />
            {/* </StyledLink> */}
            {cartQuantity > 0 && (
              <span className={styles.bubble}>{cartQuantity}</span>
            )}
          </div>
          <a
            href={userStatus === "SignedOut" ? "#/cart/signin" : "#/dashboard"}
          >
            <FontAwesomeIcon className={styles.icon} icon={faUser} />
          </a>
        </div>
      </div>
    </div>
  );
});
