import React from "react";
import * as styles from "./footer.module.scss";
import { Logo } from "../Logo/Logo";
import { Socials, Theme } from "../Socials/Socials";
import { graphql, Link, useStaticQuery } from "gatsby";
import { FooterQuery } from "../../../graphql-types";
import { usePageContext } from "../Context/pageContext";
import { Newsletter } from "../Newsletter/Newsletter";
import { Terms } from "../Terms/Terms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import "../Layout/layout.scss";

interface Props {
  terms?: boolean;
  noFooter?: boolean;
}

export const Footer: React.FC<Props> = React.memo(
  ({ terms = false, noFooter = false }) => {
    const { lang, defaultLang } = usePageContext();
    const { footer } = useStaticQuery<FooterQuery>(query);
    return (
      <>
        {!noFooter ? (
          <div
            className={`${styles.wrapper} ${terms ? "" : styles.paddingTop}`}
          >
            {terms && (
              <div className={styles.terms}>
                <Terms />
              </div>
            )}
            <div className={styles.container}>
              <button
                id="scroll-btn"
                className="sidebar-btns"
                title="Scroll to top"
              >
                <div className="halfOpacity">Up</div>
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
              <div className={styles.topSection}>
                <Logo theme={Theme.white} />
                <div className={styles.rightWrapper}>
                  <div className={styles.contact}>
                    <Link to={lang === defaultLang ? "/contact" : `/${lang}`}>
                      {"Contact us"}
                    </Link>
                  </div>
                  <div className={styles.socials}>
                    <Socials row={true} theme={Theme.white} />
                  </div>
                </div>
              </div>
              <div className={styles.middleSection}>
                <div className={styles.links}>
                  {footer?.footerItems
                    ?.filter((item: any) => item.active === true)
                    .map((item: any, index: number) => (
                      <React.Fragment key={`${index}-footer-item`}>
                        <Link
                          to={
                            lang === defaultLang
                              ? item.path
                              : `${item.path}/${lang}`
                          }
                        >
                          {item._rawLabel[lang]}
                        </Link>
                      </React.Fragment>
                    ))}
                </div>
                <Newsletter theme={Theme.white} />
              </div>
              <div className={styles.copyright}>{"© Concave 2021"}</div>
            </div>
          </div>
        ) : (
          <div className={styles.wrapper}>
            <div className={styles.termsNoFooter}>
              <Terms />
            </div>
          </div>
        )}
      </>
    );
  }
);

const query = graphql`
  query Footer {
    footer: sanityFooter {
      footerItems {
        path
        active
        _rawLabel
      }
    }
  }
`;
