import React from "react";
import * as styles from "./backbutton.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { navigate } from "gatsby";

export const BackButton: React.FC = React.memo(() => {
  return (
    <button
      onClick={(e) => {
        e.stopPropagation();
        navigate(-1);
      }}
      className={styles.backButton}
    >
      <div className={styles.label}>BACK</div>
      <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
    </button>
  );
});
