import React, { ReactNode } from "react";
import { Header } from "../Header/Header";
import * as styles from "./layout.module.scss";
import "./layout.scss";
import { TopEllipsis } from "../Ellipsis/TopEllipsis";
import { BottomEllipsis } from "../Ellipsis/BottomEllipsis";
import { Footer } from "../Footer/Footer";
import { BackButton } from "../Buttons/BackButton";

import { Link } from "gatsby";
interface LayoutProps {
  children: ReactNode;
  noBackbutton?: boolean;
  terms?: boolean;
  noFooter?: boolean;
}

export const Layout: React.FC<LayoutProps> = ({
  children,
  noBackbutton = false,
  terms = false,
  noFooter = false,
}) => {
  return (
    <>
      <TopEllipsis />
      <Header />
      {!noBackbutton && <BackButton />}
      <main>
        <div className={styles.background}>{children}</div>
      </main>
      {/* <span className={styles.elipsisBottom}></span> */}
      <Footer terms={terms} noFooter={noFooter} />
      <BottomEllipsis />
    </>
  );
};
