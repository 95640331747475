import React, { useState } from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";
import { Theme } from "../Socials/Socials";
import * as styles from "./newsletter.module.scss";
import { graphql, useStaticQuery } from "gatsby";
import { usePageContext } from "../Context/pageContext";

interface Props {
  theme: Theme;
}

export const Newsletter: React.FC<Props> = ({ theme }) => {
  const [result, setResult] = useState<any>(undefined);
  const { lang } = usePageContext();
  const [email, setEmail] = useState<string>("");
  const { page } = useStaticQuery<NewsletterQuery>(QUERY);

  return (
    <div className={styles.wrapper}>
      <div className={styles.cta}>
        {result
          ? page.newsletter?.thanks?.[lang]
          : page.newsletter?.cta?.[lang]}
      </div>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          setResult(await addToMailchimp(email));
        }}
      >
        <div className={`${styles.inputContainer} ${styles[theme]}`}>
          <input
            type="email"
            name="email"
            autoComplete="email"
            required={true}
            aria-label="email"
            placeholder={page.newsletter?.emailPlaceholder?.[lang]}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            className={`${styles.input} ${styles[theme]}`}
          />
          <button type="submit" className={`${styles.button} ${styles[theme]}`}>
            {page.newsletter?.buttonText?.[lang]}
          </button>
        </div>
      </form>
    </div>
  );
};

const QUERY = graphql`
  query newsletter {
    page: sanitySettings {
      newsletter {
        emailPlaceholder: _rawEmailPlaceholder
        buttonText: _rawNewsletterButton
        cta: _rawNewsletterCta
        thanks: _rawNewsletterThanks
      }
    }
  }
`;
